import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pricing.css'

const Pricing = (props) => {
  const [isYearly, setIsYearly] = useState(false)
  const [isHourly, setIsHourly] = useState(true)
  const [isMonthly, setIsMonthly] = useState(false)
  return (
    <div id="pricing" className="pricing-pricing23 thq-section-padding">
      <div className="pricing-max-width thq-section-max-width">
        <div className="pricing-section-title">
          <span className="pricing-text10 thq-body-small">
            {props.content1}
          </span>
          <div className="pricing-content">
            <h2 className="pricing-text11 thq-heading-2">{props.heading1}</h2>
            <p className="thq-body-large">{props.content2}</p>
          </div>
        </div>
        <div className="pricing-tabs">
          {isHourly === true && (
            <button
              onClick={() => {
                setIsYearly(false)
                setIsMonthly(false)
                setIsYearly(false)
                setIsHourly(true)
              }}
              className="pricing-button10 thq-button-animated thq-button-filled"
            >
              <span className="thq-body-small">Hourly</span>
            </button>
          )}
          {isHourly === false && (
            <button
              onClick={() => {
                setIsMonthly(false)
                setIsHourly(true)
                setIsYearly(false)
              }}
              className="pricing-button11 thq-button-animated thq-button-outline"
            >
              <span className="thq-body-small">
                <span>Hourly</span>
                <br></br>
              </span>
            </button>
          )}
          {isMonthly === false && (
            <button
              onClick={() => {
                setIsMonthly(true)
                setIsYearly(false)
                setIsHourly(false)
                setIsMonthly(true)
              }}
              className="pricing-button12 thq-button-animated thq-button-outline"
            >
              <span className="thq-body-small">Monthly</span>
            </button>
          )}
          {isMonthly === true && (
            <button className="pricing-button13 thq-button-animated thq-button-filled">
              <span className="thq-body-small">Monthly</span>
            </button>
          )}
          {isYearly === true && (
            <button className="pricing-button14 thq-button-animated thq-button-filled">
              <span className="thq-body-small">Yearly</span>
            </button>
          )}
          {isYearly === false && (
            <button
              onClick={() => {
                setIsHourly(false)
                setIsYearly(true)
                setIsMonthly(false)
                setIsMonthly(false)
              }}
              className="pricing-button15 thq-button-animated thq-button-outline"
            >
              <span className="thq-body-small">Yearly</span>
            </button>
          )}
        </div>
        {isHourly === true && (
          <div className="pricing-container1">
            <div className="pricing-column1 thq-card">
              <div className="pricing-price10">
                <div className="pricing-price11">
                  <p className="pricing-text21 thq-body-large">{props.plan1}</p>
                  <h3 className="pricing-text22 thq-heading-3">
                    {props.plan1Price}
                  </h3>
                  <p className="thq-body-large">{props.plan1Yearly}</p>
                </div>
                <div className="pricing-list1">
                  <div className="pricing-list-item10">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text24 thq-body-small">
                      {props.plan1Feature1}
                    </span>
                  </div>
                  <div className="pricing-list-item11">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text25 thq-body-small">
                      {props.plan1Feature2}
                    </span>
                  </div>
                  <div className="pricing-list-item12">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text26 thq-body-small">
                      {props.plan1Feature34}
                    </span>
                  </div>
                  <div className="pricing-list-item13">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text27 thq-body-small">
                      {'Cybersecurity checkups for homes or small offices'}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button16 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text28 thq-body-small"
                >
                  {props.plan1Action}
                </a>
              </button>
            </div>
            <div className="pricing-column2 thq-card">
              <div className="pricing-price12">
                <div className="pricing-price13">
                  <p className="pricing-text29 thq-body-large">{props.plan2}</p>
                  <h3 className="pricing-text30 thq-heading-3">
                    {props.plan2Price}
                  </h3>
                  <p className="thq-body-large">{props.plan2Yearly}</p>
                </div>
                <div className="pricing-list2">
                  <div className="pricing-list-item14">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text32 thq-body-small">
                      {'OS troubleshooting and software installations'}
                    </span>
                  </div>
                  <div className="pricing-list-item15">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text33 thq-body-small">
                      {'Remote assistance with network or device setup'}
                    </span>
                  </div>
                  <div className="pricing-list-item16">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text34 thq-body-small">
                      {'Cybersecurity guidance (MFA setup, password safety)'}
                    </span>
                  </div>
                  <div className="pricing-list-item17">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text35 thq-body-small">
                      {'Data recovery and file backup assistance'}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button17 thq-button-animated thq-button-filled">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text36 thq-body-small"
                >
                  {props.plan2Action}
                </a>
              </button>
            </div>
            <div className="pricing-column3 thq-card">
              <div className="pricing-price14">
                <div className="pricing-price15">
                  <p>
                    {props.text ?? (
                      <Fragment>
                        <p className="pricing-text97 thq-body-large">
                          Business Consultation
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3 className="pricing-text38 thq-heading-3">
                    {props.plan3Price}
                  </h3>
                  <p className="thq-body-large">{props.plan3Yearly}</p>
                </div>
                <div className="pricing-list3">
                  <div className="pricing-list-item18">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text40 thq-body-small">
                      {'IT strategy and cloud migration planning'}
                    </span>
                  </div>
                  <div className="pricing-list-item19">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text41 thq-body-small">
                      {'Cybersecurity audits and vulnerabilitiy assesments'}
                    </span>
                  </div>
                  <div className="pricing-list-item20">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text42 thq-body-small">
                      {'POS system setup and automation consulting'}
                    </span>
                  </div>
                  <div className="pricing-list-item21">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text43 thq-body-small">
                      {'Incident response and business continuity planning'}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button18 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text44 thq-body-small"
                >
                  {props.plan1Action5}
                </a>
              </button>
            </div>
          </div>
        )}
        {isMonthly === true && (
          <div className="pricing-container2">
            <div className="pricing-column4 thq-card">
              <div className="pricing-price16">
                <div className="pricing-price17">
                  <p className="pricing-text45 thq-body-large">
                    {props.plan13}
                  </p>
                  <h3 className="pricing-text46 thq-heading-3">
                    {props.plan1Price3}
                  </h3>
                  <p className="thq-body-large">{props.plan1Yearly3}</p>
                </div>
                <div className="pricing-list4">
                  <div className="pricing-list-item22">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text48 thq-body-small">
                      {props.plan1Feature13}
                    </span>
                  </div>
                  <div className="pricing-list-item23">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text49 thq-body-small">
                      {props.plan1Feature23}
                    </span>
                  </div>
                  <div className="pricing-list-item24">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text50 thq-body-small">
                      {'Cybersecurity Checkup (password safety, MFA Setup)'}
                    </span>
                  </div>
                  <div className="pricing-list-item25">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text51 thq-body-small">
                      {props.plan1Feature323}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button19 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text52 thq-body-small"
                >
                  {props.plan1Action6}
                </a>
              </button>
            </div>
            <div className="pricing-column5 thq-card">
              <div className="pricing-price18">
                <div className="pricing-price19">
                  <p className="pricing-text53 thq-body-large">
                    {props.plan23}
                  </p>
                  <h3 className="pricing-text54 thq-heading-3">
                    {props.plan2Price3}
                  </h3>
                  <p className="thq-body-large">{props.plan2Yearly3}</p>
                </div>
                <div className="pricing-list5">
                  <div className="pricing-list-item26">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text56 thq-body-small">
                      {props.plan2Feature13}
                    </span>
                  </div>
                  <div className="pricing-list-item27">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text57 thq-body-small">
                      {props.plan2Feature23}
                    </span>
                  </div>
                  <div className="pricing-list-item28">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text58 thq-body-small">
                      {props.plan2Feature33}
                    </span>
                  </div>
                  <div className="pricing-list-item29">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text59 thq-body-small">
                      {props.plan2Feature43}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button20 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text60 thq-body-small"
                >
                  {props.plan1Action7}
                </a>
              </button>
            </div>
            <div className="pricing-column6 thq-card">
              <div className="pricing-price20">
                <div className="pricing-price21">
                  <p>
                    {props.text2 ?? (
                      <Fragment>
                        <p className="pricing-text96 thq-body-large">
                          Premium Plan (For Advanced Business Needs)
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3 className="pricing-text62 thq-heading-3">
                    {props.plan3Price3}
                  </h3>
                  <p className="thq-body-large">{props.plan3Yearly3}</p>
                </div>
                <div className="pricing-list6">
                  <div className="pricing-list-item30">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text64 thq-body-small">
                      {props.plan3Feature13}
                    </span>
                  </div>
                  <div className="pricing-list-item31">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text65 thq-body-small">
                      {props.plan3Feature23}
                    </span>
                  </div>
                  <div className="pricing-list-item32">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text66 thq-body-small">
                      {props.plan3Feature33}
                    </span>
                  </div>
                  <div className="pricing-list-item33">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text67 thq-body-small">
                      {props.plan3Feature43}
                    </span>
                  </div>
                  <div className="pricing-list-item34">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text68 thq-body-small">
                      {props.plan3Feature53}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button21 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text69 thq-body-small"
                >
                  {props.plan1Action8}
                </a>
              </button>
            </div>
          </div>
        )}
        {isYearly === true && (
          <div className="pricing-container3">
            <div className="pricing-column7 thq-card">
              <div className="pricing-price22">
                <div className="pricing-price23">
                  <p className="pricing-text70 thq-body-large">
                    {props.plan12}
                  </p>
                  <h3 className="pricing-text71 thq-heading-3">
                    {props.plan1Price2}
                  </h3>
                  <p className="thq-body-large">{props.plan1Yearly2}</p>
                </div>
                <div className="pricing-list7">
                  <div className="pricing-list-item35">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text73 thq-body-small">
                      {props.plan1Feature12}
                    </span>
                  </div>
                  <div className="pricing-list-item36">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text74 thq-body-small">
                      {props.plan1Feature22}
                    </span>
                  </div>
                  <div className="pricing-list-item37">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text75 thq-body-small">
                      {props.plan1Feature33}
                    </span>
                  </div>
                  <div className="pricing-list-item38">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text76 thq-body-small">
                      {props.plan1Feature322}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button22 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text85 thq-body-small"
                >
                  {props.plan1Action2}
                </a>
              </button>
            </div>
            <div className="pricing-column8 thq-card">
              <div className="pricing-price24">
                <div className="pricing-price25">
                  <p className="pricing-text78 thq-body-large">
                    {props.plan22}
                  </p>
                  <h3 className="pricing-text79 thq-heading-3">
                    {props.plan2Price2}
                  </h3>
                  <p className="thq-body-large">{props.plan2Yearly2}</p>
                </div>
                <div className="pricing-list8">
                  <div className="pricing-list-item39">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text81 thq-body-small">
                      {props.plan2Feature12}
                    </span>
                  </div>
                  <div className="pricing-list-item40">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text82 thq-body-small">
                      {props.plan2Feature22}
                    </span>
                  </div>
                  <div className="pricing-list-item41">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text83 thq-body-small">
                      {props.plan2Feature32}
                    </span>
                  </div>
                  <div className="pricing-list-item42">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text84 thq-body-small">
                      {props.plan2Feature42}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button23 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text85 thq-body-small"
                >
                  {props.plan1Action10}
                </a>
              </button>
            </div>
            <div className="pricing-column9 thq-card">
              <div className="pricing-price26">
                <div className="pricing-price27">
                  <p>
                    {props.text1 ?? (
                      <Fragment>
                        <p className="pricing-text95 thq-body-large">
                          Premium Plan (For Advanced Business Needs)
                        </p>
                      </Fragment>
                    )}
                  </p>
                  <h3 className="pricing-text87 thq-heading-3">
                    {props.plan3Price2}
                  </h3>
                  <p className="thq-body-large">{props.plan3Yearly2}</p>
                </div>
                <div className="pricing-list9">
                  <div className="pricing-list-item43">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text89 thq-body-small">
                      {props.plan3Feature12}
                    </span>
                  </div>
                  <div className="pricing-list-item44">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text90 thq-body-small">
                      {props.plan3Feature22}
                    </span>
                  </div>
                  <div className="pricing-list-item45">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text91 thq-body-small">
                      {props.plan3Feature32}
                    </span>
                  </div>
                  <div className="pricing-list-item46">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text92 thq-body-small">
                      {props.plan3Feature42}
                    </span>
                  </div>
                  <div className="pricing-list-item47">
                    <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                      <path d="M384 690l452-452 60 60-512 512-238-238 60-60z"></path>
                    </svg>
                    <span className="pricing-text93 thq-body-small">
                      {props.plan3Feature52}
                    </span>
                  </div>
                </div>
              </div>
              <button className="pricing-button24 thq-button-animated thq-button-outline">
                <a
                  href="https://camositsolutions.square.site/s/appointments"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pricing-text94 thq-body-small"
                >
                  {props.plan1Action9}
                </a>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Pricing.defaultProps = {
  plan2: 'Remote Support ',
  plan1Price2: '$499/year',
  plan3Feature2: 'Cybersecurity audits and vulnerabilitiy assesments',
  plan3Feature12: 'Unlimited remote support and 10 on-site hours per month',
  plan1Yearly3: '$499/year (save 2 months)',
  plan1Price: '$90/hour',
  text1: undefined,
  plan1Action2: 'Book Now',
  plan3Price2: '$2999/year',
  plan2Yearly2: '$149/month (extra $300/year)',
  plan1Price3: '$49/month',
  plan1Feature3: 'Printer installation and device configuration',
  plan3Yearly: 'Charged per minute ($2 per minute)',
  plan3Price3: '$299/month',
  plan3Feature32: 'Cloud migration consulting and business automation support',
  plan1Yearly2: '$49/month (extra $100)',
  plan3Feature22:
    'Advanced cybersecurity audits and incident response coverage',
  plan13: 'Basic Plan (for Individuals and Home Users)',
  plan1Feature1: 'Hardware repairs and upgrades (SSD, Ram Installation, etc.)',
  plan1Feature34: 'Cybersecurity Checkup (password safety, MFA Setup)',
  plan2Feature2: 'Remote assistance with network or device setup',
  plan1: 'On Site Repair/Support',
  plan3Feature42: 'Annual custom membership portal or POS upgrade',
  plan3Feature13: 'Unlimited remote support and 10 on-site hours per month',
  plan2Feature3: 'Cybersecurity guidance (MFA setup, password safety)',
  plan1Feature23: 'Home network security assesment (one assesment per package)',
  plan2Price: '$60/hour',
  plan2Price2: '$1499/year',
  content1: 'Choose the perfect plan for you',
  plan1Feature33: 'Cybersecurity Checkup (password safety, MFA Setup)',
  plan1Action9: 'Book Now',
  plan3Feature53: '20% discount on all other services',
  plan1Action5: 'Book Now',
  plan3Feature43: 'Annual custom membership portal or POS upgrade',
  plan2Action: 'Book Now',
  plan3Feature33: 'Cloud migration consulting and business automation support',
  plan1Action7: 'Book Now',
  plan12: 'Basic Plan (for Individuals and Home Users)',
  plan3Yearly3: '$2999/year (save 2 months)',
  plan2Feature13: '5 hours of remote or on-site support per month',
  heading1: 'Pricing plan',
  plan2Feature43: '15% discount on additional services',
  plan1Feature322: '10% discount on in-person repairs',
  plan2Feature23: 'Quarterly POS system check and maintenance',
  plan1Action8: 'Book Now',
  plan3Feature23:
    'Advanced cybersecurity audits and incident response coverage',
  plan1Feature2: 'Network setup and troubleshooting (home or small business)',
  plan2Feature1: 'OS troubleshooting and software installations',
  plan23: 'Professional Plan (For Small Businesses)',
  plan1Feature12: '1 Hour of remote support per month\n',
  plan1Feature13: '1 Hour of remote support per month',
  plan3Feature4: 'Incident response and business continuity planning',
  plan1Action6: 'Book Now',
  plan3Feature1: 'IT strategy and cloud migration planning',
  plan1Action: 'Book Now',
  plan1Yearly: 'No fix, no fee. Charged per half hour',
  plan1Feature323: '10% discount on in-person repairs',
  plan3Yearly2: '$299/month (extra $600/year)',
  plan2Yearly3: '$1499/year (save 2 months)',
  plan1Feature32: 'Cybersecurity checkups for homes or small offices',
  plan3Price: '$120/hour',
  content2:
    "We aren't like other IT Service providers. We offer upfront transparent monthly costs for our Callout and Remote services. Subscription fees do not include Parts required for repairs.",
  plan1Feature22: 'Home network security assesment (one assesment per package)',
  plan2Feature33: 'Cybersecurity audit and phishing training twice a year',
  plan3Feature3: 'POS system setup and automation consulting',
  plan2Feature32: 'Cybersecurity audit and phishing training twice a year',
  text2: undefined,
  text: undefined,
  plan22: 'Professional Plan (For Small Businesses)',
  plan2Feature42: '15% discount on additional services',
  plan1Action10: 'Book Now',
  plan2Feature12: '5 hours of remote or on-site support per month',
  plan2Feature4: 'Data recovery and file backup assistance',
  plan3Feature52: '20% discount on all other services',
  plan2Price3: '$149/month',
  plan2Yearly: 'No fix, no fee. Charged per half hour',
  plan2Feature22: 'Quarterly POS system check and maintenance',
}

Pricing.propTypes = {
  plan2: PropTypes.string,
  plan1Price2: PropTypes.string,
  plan3Feature2: PropTypes.string,
  plan3Feature12: PropTypes.string,
  plan1Yearly3: PropTypes.string,
  plan1Price: PropTypes.string,
  text1: PropTypes.element,
  plan1Action2: PropTypes.string,
  plan3Price2: PropTypes.string,
  plan2Yearly2: PropTypes.string,
  plan1Price3: PropTypes.string,
  plan1Feature3: PropTypes.string,
  plan3Yearly: PropTypes.string,
  plan3Price3: PropTypes.string,
  plan3Feature32: PropTypes.string,
  plan1Yearly2: PropTypes.string,
  plan3Feature22: PropTypes.string,
  plan13: PropTypes.string,
  plan1Feature1: PropTypes.string,
  plan1Feature34: PropTypes.string,
  plan2Feature2: PropTypes.string,
  plan1: PropTypes.string,
  plan3Feature42: PropTypes.string,
  plan3Feature13: PropTypes.string,
  plan2Feature3: PropTypes.string,
  plan1Feature23: PropTypes.string,
  plan2Price: PropTypes.string,
  plan2Price2: PropTypes.string,
  content1: PropTypes.string,
  plan1Feature33: PropTypes.string,
  plan1Action9: PropTypes.string,
  plan3Feature53: PropTypes.string,
  plan1Action5: PropTypes.string,
  plan3Feature43: PropTypes.string,
  plan2Action: PropTypes.string,
  plan3Feature33: PropTypes.string,
  plan1Action7: PropTypes.string,
  plan12: PropTypes.string,
  plan3Yearly3: PropTypes.string,
  plan2Feature13: PropTypes.string,
  heading1: PropTypes.string,
  plan2Feature43: PropTypes.string,
  plan1Feature322: PropTypes.string,
  plan2Feature23: PropTypes.string,
  plan1Action8: PropTypes.string,
  plan3Feature23: PropTypes.string,
  plan1Feature2: PropTypes.string,
  plan2Feature1: PropTypes.string,
  plan23: PropTypes.string,
  plan1Feature12: PropTypes.string,
  plan1Feature13: PropTypes.string,
  plan3Feature4: PropTypes.string,
  plan1Action6: PropTypes.string,
  plan3Feature1: PropTypes.string,
  plan1Action: PropTypes.string,
  plan1Yearly: PropTypes.string,
  plan1Feature323: PropTypes.string,
  plan3Yearly2: PropTypes.string,
  plan2Yearly3: PropTypes.string,
  plan1Feature32: PropTypes.string,
  plan3Price: PropTypes.string,
  content2: PropTypes.string,
  plan1Feature22: PropTypes.string,
  plan2Feature33: PropTypes.string,
  plan3Feature3: PropTypes.string,
  plan2Feature32: PropTypes.string,
  text2: PropTypes.element,
  text: PropTypes.element,
  plan22: PropTypes.string,
  plan2Feature42: PropTypes.string,
  plan1Action10: PropTypes.string,
  plan2Feature12: PropTypes.string,
  plan2Feature4: PropTypes.string,
  plan3Feature52: PropTypes.string,
  plan2Price3: PropTypes.string,
  plan2Yearly: PropTypes.string,
  plan2Feature22: PropTypes.string,
}

export default Pricing
