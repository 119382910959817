import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial.css'

const Testimonial = (props) => {
  return (
    <div id="reviews" className="thq-section-padding">
      <div className="testimonial-max-width thq-section-max-width">
        <div className="testimonial-container10">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <span className="testimonial-text11 thq-body-small">
            {props.content1}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card10">
                <div className="testimonial-container12">
                  <img
                    alt={props.author4Alt2}
                    src={props.author4Src2}
                    className="testimonial-image10"
                  />
                  <div className="testimonial-container13">
                    <strong className="thq-body-large">
                      {props.author4Name2}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position2}
                    </span>
                  </div>
                </div>
                <span className="thq-body-small">{props.review42}</span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card11">
                <div className="testimonial-container14">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial-image11"
                  />
                  <div className="testimonial-container15">
                    <strong>
                      {props.text ?? (
                        <Fragment>
                          <strong className="testimonial-text61 thq-body-large">
                            <span>Wayne M.</span>
                            <br></br>
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.text2 ?? (
                        <Fragment>
                          <span className="testimonial-text42 thq-body-small">
                            <span>Individual Client - RSL NSW</span>
                            <br></br>
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.text1 ?? (
                    <Fragment>
                      <span className="testimonial-text65 thq-body-small">
                        <span>
                          Cam was great,punctual and explained everything to me
                        </span>
                        <br></br>
                        <span>
                          He also called me later to see if everything was going
                          OK
                        </span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card12">
                <div className="testimonial-container16">
                  <img
                    alt={props.author1Alt1}
                    src={props.author1Src1}
                    className="testimonial-image12"
                  />
                  <div className="testimonial-container17">
                    <strong>
                      {props.text3 ?? (
                        <Fragment>
                          <strong className="testimonial-text48 thq-body-large">
                            <span>Robyn L.</span>
                            <br></br>
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.text21 ?? (
                        <Fragment>
                          <span className="testimonial-text70 thq-body-small">
                            <br></br>
                            <span>Individual Client - RSL NSW</span>
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.text11 ?? (
                    <Fragment>
                      <span className="testimonial-text56 thq-body-small">
                        Can&apos;t speak highly enough of Cameron. He&apos;s
                        saved me numerous times. Nothing is too much trouble and
                        the job is expediated expertly in a very short amount of
                        time. Thanks so much again.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card13">
                <div className="testimonial-container18">
                  <img
                    alt={props.author1Alt11}
                    src={props.author1Src11}
                    className="testimonial-image13"
                  />
                  <div className="testimonial-container19">
                    <strong>
                      {props.text31 ?? (
                        <Fragment>
                          <strong className="testimonial-text53 thq-body-large">
                            <span>Barbara M. </span>
                            <br></br>
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.text211 ?? (
                        <Fragment>
                          <span className="testimonial-text51 thq-body-small">
                            Individual Client - Ourimbah RSL Sub Branch
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.text111 ?? (
                    <Fragment>
                      <span className="testimonial-text52 thq-body-small">
                        Cameron goes above and beyond with his service, very
                        happy with his work and ethics.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card14">
                <div className="testimonial-container20">
                  <img
                    alt={props.author1Alt111}
                    src={props.author1Src111}
                    className="testimonial-image14"
                  />
                  <div className="testimonial-container21">
                    <strong>
                      {props.text311 ?? (
                        <Fragment>
                          <strong className="testimonial-text45 thq-body-large">
                            <span>Major S.</span>
                            <br></br>
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.text2111 ?? (
                        <Fragment>
                          <span className="testimonial-text60 thq-body-small">
                            Business Client - Shahi Mahal Indian Restaurant
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.text1111 ?? (
                    <Fragment>
                      <span className="testimonial-text64 thq-body-small">
                        I hired Cameron to set up and install my Point of Sales
                        System and couldn&apos;t be happier. Would definetly be
                        calling back for future services.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card15">
                <div className="testimonial-container22">
                  <img
                    alt={props.author1Alt1111}
                    src={props.author1Src1111}
                    className="testimonial-image15"
                  />
                  <div className="testimonial-container23">
                    <strong>
                      {props.text3111 ?? (
                        <Fragment>
                          <strong className="testimonial-text57 thq-body-large">
                            <span>Amelie A.</span>
                            <br></br>
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.text21111 ?? (
                        <Fragment>
                          <span className="testimonial-text69 thq-body-small">
                            Business Client - Shahi Mahal Indian Restaurant
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.text11111 ?? (
                    <Fragment>
                      <span className="testimonial-text73 thq-body-small">
                        Cam is very knowledgeable when it comes to technology,
                        but also patient and humble. Very easy and comfortable
                        to work with! Thank you Cam
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card16">
                <div className="testimonial-container24">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial-image16"
                  />
                  <div className="testimonial-container25">
                    <strong className="thq-body-large">
                      {props.author2Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author2Position}
                    </span>
                  </div>
                </div>
                <span className="thq-body-small">{props.review2}</span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card17">
                <div className="testimonial-container26">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial-image17"
                  />
                  <div className="testimonial-container27">
                    <strong className="thq-body-large">
                      {props.author3Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author3Position}
                    </span>
                  </div>
                </div>
                <span className="thq-body-small">{props.review3}</span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card18">
                <div className="testimonial-container28">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial-image18"
                  />
                  <div className="testimonial-container29">
                    <strong className="thq-body-large">
                      {props.author4Name}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position}
                    </span>
                  </div>
                </div>
                <span className="thq-body-small">{props.review4}</span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card testimonial-card19">
                <div className="testimonial-container30">
                  <img
                    alt={props.author4Alt1}
                    src={props.author4Src1}
                    className="testimonial-image19"
                  />
                  <div className="testimonial-container31">
                    <strong className="thq-body-large">
                      {props.author4Name1}
                    </strong>
                    <span className="thq-body-small">
                      {props.author4Position1}
                    </span>
                  </div>
                </div>
                <span className="thq-body-small">{props.review41}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Testimonial.defaultProps = {
  author3Name: 'Geeks2U Client',
  author1Src:
    'https://images.unsplash.com/photo-1719165433004-c6d4b3e5a7a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5N3w&ixlib=rb-4.0.3&q=80&w=1080',
  text2: undefined,
  author1Alt111: 'John Smith - Small Business Owner',
  review42:
    "Can't speak highly enough of Cameron. He is always prompt and nothing is a bother. His work is first rate. He is our 'go to man' for all our computer problems.",
  text311: undefined,
  author4Position1: 'Individual Client',
  text3: undefined,
  text211: undefined,
  review3: 'Patient, attentive, friendly and resolved every issue. Thanks!',
  author1Alt1111: 'John Smith - Small Business Owner',
  author4Src1:
    'https://images.unsplash.com/photo-1578863873955-40421002faa6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5OHw&ixlib=rb-4.0.3&q=80&w=1080',
  author4Name: 'Geeks2U Client',
  author1Src1111:
    'https://images.unsplash.com/photo-1719165433004-c6d4b3e5a7a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5N3w&ixlib=rb-4.0.3&q=80&w=1080',
  text111: undefined,
  author4Name1: 'Geeks2U Client',
  author2Alt: 'Emily Johnson - Individual Client',
  review2:
    'Cameron was so helpful. I would not have figured out how to connect my tech without him. he was patient and very knowledgeable. Highly recommended',
  author3Src:
    'https://images.unsplash.com/photo-1667486946512-0ac8ec155d31?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5OXw&ixlib=rb-4.0.3&q=80&w=1080',
  review41:
    'Friendly and great communication. Our Geek, Cameron was on time and very knowledgeable, very happy with the outcome.',
  text31: undefined,
  author1Src1:
    'https://images.unsplash.com/photo-1719165433004-c6d4b3e5a7a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5N3w&ixlib=rb-4.0.3&q=80&w=1080',
  author1Alt: 'John Smith - Small Business Owner',
  text11: undefined,
  text3111: undefined,
  author1Alt11: 'John Smith - Small Business Owner',
  text2111: undefined,
  author1Src11:
    'https://images.unsplash.com/photo-1719165433004-c6d4b3e5a7a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5N3w&ixlib=rb-4.0.3&q=80&w=1080',
  author4Src:
    'https://images.unsplash.com/photo-1578863873955-40421002faa6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5OHw&ixlib=rb-4.0.3&q=80&w=1080',
  heading1: 'Customer Reviews',
  author4Name2: 'Ian T.',
  author4Position: 'Individual Client',
  review4:
    'Friendly and great communication. Our Geek, Cameron was on time and very knowledgeable, very happy with the outcome.',
  author4Alt: 'Sarah Davis - Parent',
  author4Alt1: 'Sarah Davis - Parent',
  author4Alt2: 'Sarah Davis - Parent',
  author4Src2:
    'https://images.unsplash.com/photo-1578863873955-40421002faa6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5OHw&ixlib=rb-4.0.3&q=80&w=1080',
  text: undefined,
  author4Position2: 'Individual Client - Ourimbah RSL Sub Branch',
  author2Position: 'Individual Client',
  author2Src:
    'https://images.unsplash.com/photo-1533689476487-034f57831a58?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5OHw&ixlib=rb-4.0.3&q=80&w=1080',
  content1: 'Read what our satisfied customers have to say about our services.',
  text1111: undefined,
  author1Src111:
    'https://images.unsplash.com/photo-1719165433004-c6d4b3e5a7a5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODcyMzc5N3w&ixlib=rb-4.0.3&q=80&w=1080',
  author2Name: 'Geeks2U Customer',
  author3Alt: 'Michael Brown - Restaurant Owner',
  text1: undefined,
  text21111: undefined,
  author3Position: 'Individual Client\n',
  text21: undefined,
  text11111: undefined,
  author1Alt1: 'John Smith - Small Business Owner',
}

Testimonial.propTypes = {
  author3Name: PropTypes.string,
  author1Src: PropTypes.string,
  text2: PropTypes.element,
  author1Alt111: PropTypes.string,
  review42: PropTypes.string,
  text311: PropTypes.element,
  author4Position1: PropTypes.string,
  text3: PropTypes.element,
  text211: PropTypes.element,
  review3: PropTypes.string,
  author1Alt1111: PropTypes.string,
  author4Src1: PropTypes.string,
  author4Name: PropTypes.string,
  author1Src1111: PropTypes.string,
  text111: PropTypes.element,
  author4Name1: PropTypes.string,
  author2Alt: PropTypes.string,
  review2: PropTypes.string,
  author3Src: PropTypes.string,
  review41: PropTypes.string,
  text31: PropTypes.element,
  author1Src1: PropTypes.string,
  author1Alt: PropTypes.string,
  text11: PropTypes.element,
  text3111: PropTypes.element,
  author1Alt11: PropTypes.string,
  text2111: PropTypes.element,
  author1Src11: PropTypes.string,
  author4Src: PropTypes.string,
  heading1: PropTypes.string,
  author4Name2: PropTypes.string,
  author4Position: PropTypes.string,
  review4: PropTypes.string,
  author4Alt: PropTypes.string,
  author4Alt1: PropTypes.string,
  author4Alt2: PropTypes.string,
  author4Src2: PropTypes.string,
  text: PropTypes.element,
  author4Position2: PropTypes.string,
  author2Position: PropTypes.string,
  author2Src: PropTypes.string,
  content1: PropTypes.string,
  text1111: PropTypes.element,
  author1Src111: PropTypes.string,
  author2Name: PropTypes.string,
  author3Alt: PropTypes.string,
  text1: PropTypes.element,
  text21111: PropTypes.element,
  author3Position: PropTypes.string,
  text21: PropTypes.element,
  text11111: PropTypes.element,
  author1Alt1: PropTypes.string,
}

export default Testimonial
