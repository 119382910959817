import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero from '../components/hero'
import Features1 from '../components/features1'
import CTA from '../components/cta'
import Features2 from '../components/features2'
import Pricing from '../components/pricing'
import Steps from '../components/steps'
import Testimonial from '../components/testimonial'
import Contact from '../components/contact'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Camos IT Solutions</title>
      </Helmet>
      <Navbar link2="Services"></Navbar>
      <Hero></Hero>
      <Features1></Features1>
      <CTA></CTA>
      <Features2></Features2>
      <Pricing
        text={
          <Fragment>
            <span className="home-text10 thq-body-large">
              Business Consultation
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text11 thq-body-large">
              Premium Plan (For Advanced Business Needs)
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text12 thq-body-large">
              Premium Plan (For Advanced Business Needs)
            </span>
          </Fragment>
        }
        content2="We aren't like other IT Service providers. We offer upfront transparent pricing. Please note that subscription fees do not include parts required for repairs."
        plan1Feature3="Cybersecurity Checkup (password safety, MFA Setup)"
        plan2Feature1="5 hours of remote or on-site support per month"
        plan2Feature2="Quarterly POS system check and maintenance"
        plan2Feature3="Cybersecurity audit and phishing training twice a year"
        plan2Feature4="15% discount on additional services"
        plan3Feature1="Unlimited remote support and 10 on-site hours per month"
        plan3Feature2="Advanced cybersecurity audits and incident response coverage"
        plan3Feature3="Cloud migration consulting and business automation support"
        plan3Feature4="Annual custom membership portal or POS upgrade"
        plan3Feature5="20% discount on additional services"
        plan1Feature32="10% discount on in-person repairs and additional services"
      ></Pricing>
      <Steps></Steps>
      <Testimonial
        text={
          <Fragment>
            <span className="home-text13 thq-body-large">
              <span>Wayne M.</span>
              <br></br>
            </span>
          </Fragment>
        }
        text1={
          <Fragment>
            <span className="home-text16 thq-body-small">
              <span>Cam was great,punctual and explained everything to me</span>
              <br></br>
              <span>
                He also called me later to see if everything was going OK
              </span>
            </span>
          </Fragment>
        }
        text2={
          <Fragment>
            <span className="home-text20 thq-body-small">
              <span>Individual Client - RSL NSW</span>
              <br></br>
            </span>
          </Fragment>
        }
        text3={
          <Fragment>
            <span className="home-text23 thq-body-large">
              <span>Robyn L.</span>
              <br></br>
            </span>
          </Fragment>
        }
        text11={
          <Fragment>
            <span className="home-text26 thq-body-small">
              Can&apos;t speak highly enough of Cameron. He&apos;s saved me
              numerous times. Nothing is too much trouble and the job is
              expediated expertly in a very short amount of time. Thanks so much
              again.
            </span>
          </Fragment>
        }
        text21={
          <Fragment>
            <span className="home-text27 thq-body-small">
              <span>Individual Client - RSL NSW</span>
              <br></br>
            </span>
          </Fragment>
        }
        text31={
          <Fragment>
            <span className="home-text30 thq-body-large">
              <span>Barbara M. </span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="home-text33 thq-body-small">
              Cameron goes above and beyond with his service, very happy with
              his work and ethics.
            </span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="home-text34 thq-body-small">
              Individual Client - Ourimbah RSL Sub Branch
            </span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="home-text35 thq-body-large">
              <span>Major S.</span>
              <br></br>
            </span>
          </Fragment>
        }
        text1111={
          <Fragment>
            <span className="home-text38 thq-body-small">
              I hired Cameron to set up and install my Point of Sales System and
              couldn&apos;t be happier. Would definetly be calling back for
              future services.
            </span>
          </Fragment>
        }
        text2111={
          <Fragment>
            <span className="home-text39 thq-body-small">
              Business Client - Shahi Mahal Indian Restaurant
            </span>
          </Fragment>
        }
        text3111={
          <Fragment>
            <span className="home-text40 thq-body-large">
              <span>Amelie A.</span>
              <br></br>
            </span>
          </Fragment>
        }
        text11111={
          <Fragment>
            <span className="home-text43 thq-body-small">
              Cam is very knowledgeable when it comes to technology, but also
              patient and humble. Very easy and comfortable to work with! Thank
              you Cam
            </span>
          </Fragment>
        }
        text21111={
          <Fragment>
            <span className="home-text44 thq-body-small">
              Business Client - Shahi Mahal Indian Restaurant
            </span>
          </Fragment>
        }
      ></Testimonial>
      <Contact
        email1="hello@camositsolutions.com.au"
        content3="We are available any day of the week from 9:00 AM to 5:00 PM."
      ></Contact>
    </div>
  )
}

export default Home
