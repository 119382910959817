import React from 'react'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <header className="navbar-container">
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar-image1"
        />
        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-links1">
            <span className="thq-body-small thq-link">{props.link1}</span>
            <a
              href="#services"
              className="navbar-link21 thq-body-small thq-link"
            >
              {props.link2}
            </a>
            <a
              href="#pricing"
              className="navbar-link31 thq-body-small thq-link"
            >
              {props.link3}
            </a>
            <a
              href="#reviews"
              className="navbar-link41 thq-body-small thq-link"
            >
              {props.link4}
            </a>
            <a
              href="#contactUs"
              className="navbar-link51 thq-body-small thq-link"
            >
              {props.link5}
            </a>
          </nav>
          <div className="navbar-buttons1">
            <button className="navbar-action11 thq-button-animated thq-button-filled">
              <a
                href="https://camositsolutions.square.site/s/appointments"
                target="_blank"
                rel="noreferrer noopener"
                className="navbar-link6 thq-body-small"
              >
                <span>Book Now</span>
                <br></br>
              </a>
            </button>
            <button className="navbar-action21 thq-button-animated thq-button-outline">
              <a
                href="tel:+61243131797"
                className="navbar-link7 thq-body-small"
              >
                Call Us
              </a>
            </button>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar-icon1">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div className="navbar-nav">
            <div className="navbar-top">
              <img
                alt={props.logoAlt}
                src={props.logoSrc}
                className="navbar-logo"
              />
              <div data-thq="thq-close-menu" className="navbar-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbar-icon3">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-links2">
              <span className="thq-body-small thq-link">{props.link1}</span>
              <span className="thq-body-small thq-link">{props.link2}</span>
              <span className="thq-body-small thq-link">{props.link3}</span>
              <span className="thq-body-small thq-link">{props.link4}</span>
              <span className="thq-body-small thq-link">{props.link5}</span>
            </nav>
          </div>
          <div className="navbar-buttons2">
            <button className="thq-button-filled">Login</button>
            <button className="thq-button-outline">Register</button>
          </div>
        </div>
      </header>
    </header>
  )
}

Navbar.defaultProps = {
  logoAlt: 'Camos IT Solutions Logo',
  action2: 'Scroll to Services section',
  link3: 'Pricing',
  logoSrc: '/camos%20it%20solutions%20business%20card%20(2)-1500h.png',
  link1: 'Home',
  link2: 'Services',
  action1: 'Go to Home section',
  link4: 'Reviews',
  link5: 'Contact Us',
}

Navbar.propTypes = {
  logoAlt: PropTypes.string,
  action2: PropTypes.string,
  link3: PropTypes.string,
  logoSrc: PropTypes.string,
  link1: PropTypes.string,
  link2: PropTypes.string,
  action1: PropTypes.string,
  link4: PropTypes.string,
  link5: PropTypes.string,
}

export default Navbar
